exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-vagas-tsx": () => import("./../../../src/pages/vagas.tsx" /* webpackChunkName: "component---src-pages-vagas-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-busque-vagas-de-vendedor-em-goiania-estrategias-eficientes-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/busque-vagas-de-vendedor-em-goiania-estrategias-eficientes/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-busque-vagas-de-vendedor-em-goiania-estrategias-eficientes-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-como-criar-minha-conta-no-vendoor-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/como-criar-minha-conta-no-vendoor/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-como-criar-minha-conta-no-vendoor-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-como-se-preparar-para-uma-entrevista-de-emprego-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/como-se-preparar-para-uma-entrevista-de-emprego/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-como-se-preparar-para-uma-entrevista-de-emprego-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-como-se-tornar-um-vendedor-de-sucesso-em-goiania-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/como-se-tornar-um-vendedor-de-sucesso-em-goiania/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-como-se-tornar-um-vendedor-de-sucesso-em-goiania-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-confira-as-melhores-oportunidades-de-emprego-em-goiania-e-regiao-26-agosto-2024-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/confira-as-melhores-oportunidades-de-emprego-em-goiania-e-regiao-26-agosto-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-confira-as-melhores-oportunidades-de-emprego-em-goiania-e-regiao-26-agosto-2024-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-encontre-as-melhores-oportunidades-de-emprego-em-goiania-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/encontre-as-melhores-oportunidades-de-emprego-em-goiania/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-encontre-as-melhores-oportunidades-de-emprego-em-goiania-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-encontre-as-melhores-vagas-em-goiania-na-plataforma-vendoor-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/encontre-as-melhores-vagas-em-goiania-na-plataforma-vendoor/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-encontre-as-melhores-vagas-em-goiania-na-plataforma-vendoor-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-guia-completo-para-encontrar-empregos-em-goiania-dicas-essenciais-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/guia-completo-para-encontrar-empregos-em-goiania-dicas-essenciais/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-guia-completo-para-encontrar-empregos-em-goiania-dicas-essenciais-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-oportunidades-de-emprego-em-goiania-as-melhores-vagas-da-semana-23-agosto-2024-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/oportunidades-de-emprego-em-goiania-as-melhores-vagas-da-semana-23-agosto-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-oportunidades-de-emprego-em-goiania-as-melhores-vagas-da-semana-23-agosto-2024-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vaga-de-vendedor-encontre-oportunidades-empolgantes-na-area-de-vendas-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/vaga-de-vendedor-encontre-oportunidades-empolgantes-na-area-de-vendas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vaga-de-vendedor-encontre-oportunidades-empolgantes-na-area-de-vendas-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-da-semana-22-julho-2024-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/vagas-da-semana-22-julho-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-da-semana-22-julho-2024-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-de-emprego-em-goiania-confira-as-melhores-oportunidades-14-agosto-2024-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/vagas-de-emprego-em-goiania-confira-as-melhores-oportunidades-14-agosto-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-de-emprego-em-goiania-confira-as-melhores-oportunidades-14-agosto-2024-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-de-emprego-em-goiania-e-aparecida-de-goiania-confira-as-melhores-02-agosto-2024-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/vagas-de-emprego-em-goiania-e-aparecida-de-goiania-confira-as-melhores-02-agosto-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-de-emprego-em-goiania-e-aparecida-de-goiania-confira-as-melhores-02-agosto-2024-index-mdx" */),
  "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-de-emprego-em-goiania-e-aparecida-de-goiania-confira-as-melhores-07-agosto-2024-index-mdx": () => import("./../../../src/templates/blog-post/blog-post.tsx?__contentFilePath=/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/vagas-de-emprego-em-goiania-e-aparecida-de-goiania-confira-as-melhores-07-agosto-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx-content-file-path-src-blog-vagas-de-emprego-em-goiania-e-aparecida-de-goiania-confira-as-melhores-07-agosto-2024-index-mdx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-position-tsx": () => import("./../../../src/templates/position.tsx" /* webpackChunkName: "component---src-templates-position-tsx" */),
  "component---src-templates-vacancy-tsx": () => import("./../../../src/templates/vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancy-tsx" */),
  "component---src-templates-work-field-tsx": () => import("./../../../src/templates/work-field.tsx" /* webpackChunkName: "component---src-templates-work-field-tsx" */)
}

